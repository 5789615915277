///
// Toggle
///
.c-toggle--nav {
    display: inline-block;
    position: absolute;
    top: 13px;
    right: 15px;
    width: 32px;
    height: 32px;
    &.is-active {
        .c-toggle__icon {
            background-color: transparent;
            &:after,
            &:before { top: 0; }
            &:after { transform: rotate(-45deg); }
            &:before { transform: rotate(45deg); }
        }
    }
}
///
// Toggle Title
///

.c-toggle__title {
    color: white;
    right: 40px;
    position: absolute;
    text-transform: uppercase;
    font-size: rem(30px);
    top: -8px;
    font-weight: 600;
}
///
// Toggle Icon
///
.c-toggle__icon {
    display: inline-block;
    position: absolute;
    top: 30%;
    right: 0;
    width: 32px;
    height: 4px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 0;
    transition: transform 0.3s;

    &:after,
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        width: 32px;
        height: 4px;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        border-radius: 0;
        transition: transform 0.3s;
    }

    &:after {
        top: 8px;
    }

    &:before {
        bottom: 8px;
    }
}
