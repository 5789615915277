.alignnone,
.alignleft,
.alignright {
    // Medium Max Desktops
    @include media-max(md) {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.alignleft,
.alignright {
    margin-bottom: rem(20px);
}

.alignleft {
    // Medium Desktops
    @include media(md) {
        float: left;
        margin-right: rem(20px);
    }
}

.alignright {
    // Medium Desktops
    @include media(md) {
        float: right;
        margin-left: rem(20px);
    }
}

.gallery-columns-3 {
    figure {
      width: 33.33%;
      display: inline-block;
      margin: 0px auto;
      padding: 0.5rem;
      max-height: 240px;
      overflow: hidden;
      margin-bottom: 10px;
      img {
          width: 100%;
          margin: 0px auto;
          display: inline-block;
      }
    }
}
.gallery-columns-4 {
    figure {
        width: 25%;
        display: inline-block;
        margin: 0px auto;
        padding: 0.5rem;
        max-height: 240px;
        overflow: hidden;
        margin-bottom: 10px;
        img {
            width: 100%;
            margin: 0px auto;
            display: inline-block;
        }
    }
}

.gallery-columns-5 {
    figure {
        width: 20%;
        display: inline-block;
        margin: 0px auto;
        padding: 0.5rem;
        max-height: 240px;
        overflow: hidden;
        margin-bottom: 10px;
        img {
            width: 100%;
            margin: 0px auto;
            display: inline-block;
        }
    }
}
