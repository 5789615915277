///
//  Logo fix for logo when logged in
///
body.logged-in.admin-bar {
    @include media(md){
        margin-top: 0px;
        position: relative;
    }
}
///
// Soliloquy
///
.soliloquy-image {
    width: 100% !important;
}
.tablepress td, .tablepress p {
    
}
