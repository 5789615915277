///
// Create container
///
@mixin make-container($gutter: $base-gutter) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-left: rem(halve($gutter));
    padding-right: rem(halve($gutter));
}

///
// Create row
///
@mixin make-row($gutter: $base-gutter) {
    margin-left: neg(rem(halve($gutter)));
    margin-right: neg(rem(halve($gutter)));
    @include clearfix;
}
