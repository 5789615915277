.c-content--intro {
    padding-top: rem(45px);
    background-color: color('beige');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: rem(460px);

    @include media(md){
        min-height: rem(315px);
        padding-top: rem(130px);
        padding-bottom: rem(85px);
    }
    .o-container{
        max-width: rem(1200px);
        .c-content__inner {
            text-align: center;

            h1 {
                font-size: rem(40px);
                line-height: rem(40px);
                @include media (md) {
                    font-size: rem(80px);
                    line-height: rem(80px);
                }

            }
            h4 {
                color: color('green', 'alt');
                font-size: rem(22px);
                line-height: rem(22px);
                font-family: $montserrat-regular;

                @include media (md){
                    font-size: rem(24px);
                    line-height: rem(24px);
                }

            }
            p{
                font-size: rem(20px);
                //max-width: rem(800px);
                @include media (md){
                    font-size: rem(24px);
                }
            }
            // Utility text layout fixes
            .u-text-left,
            .u-text-center,
            .u-text-right {
                text-align: center;
            }
            .u-text-left{
                @include media (md){
                    text-align: left;
                }
            }
            .u-text-center{
                @include media (md){
                    text-align: center;
                }
            }
            .u-text-right {
                @include media (md){
                    text-align: right;
                }
            }
            .o-row {

                &:first-child{
                    padding-bottom: rem(40px);

                    @include media(md ){
                        padding-bottom: rem(52px);
                    }

                }

            }

        }

    }

}
