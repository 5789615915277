.c-nav {
    font-family: $montserrat-regular;
    color: white;
    // Medium Max Desktops
    @include media-max(md) {
        position: relative;
    }
}

///
// List
///
.c-nav__list {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    li {
        position: relative;
        color: white;
        padding-right: rem(16px);
        padding-bottom: rem(8px);
        padding-left:  rem(16px);
        font-weight: 600;
        // Medium Desktops
        @include media(md) {
            display: inline-block;
            margin-bottom: 0;
            vertical-align: top;
            padding-left: rem(8px);
            padding-right:  rem(8px);
        }

        &:hover {
            ul {
                // Medium Desktops
                @include media(md) {
                    display: block;
                }
            }
        }
    }

    li li {
        position: relative;
        display: block;
        // Medium Desktops
        @include media(md) {
            margin-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 1rem;
            padding-right: 1rem;
            line-height: 1rem;
            padding: rem(4px);
        }
    }

    a {
        font-size: em(14px);
        color: white;
        text-decoration: none;
        text-transform: uppercase;

        @include link-states() {
            color: color('grey', 'default');
            text-decoration: none;
        }
    }

    ul {
        list-style: none;

        // Medium Desktops
        @include media(md) {
            display: none;
            position: absolute;
            top: 100%;
            left: rem(0px);
            min-width: rem(248px);
            min-height: rem(45px);
            padding: rem(15px);
            background-color: color('brown', 'default');
            z-index: z('dropdown');
        }
    }
}
///
// desktop
///
.c-nav--desktop {
   text-align: right;
   ul ul{
    text-align: left;
   }
}
///
// Mobile
///
.c-nav--mobile {
    position: relative;
    height: 50px;
    background-color: color('green', 'default');

    // Medium Desktops
    @include media(md) {
        display: none;
    }

    ul{

    }
    li, ul li, .sub-menu, .sub-menu li {
        margin: rem(0px);
        padding: rem(0px);
    }


    .c-nav__list {
        display: none;
        position: absolute;
        top: 100%;
        z-index: z('nav-mobile');
        width: 100%;
        margin-top: 0;
        padding: rem(20px) rem(15px) rem(15px);
        background-color: color('green','default');

    }

    .c-info {
        position: absolute;
        top: rem(3px);
        left: rem(20px);
        font-size: rem(30px);
        color: white;
        text-decoration: none;

        @include link-states() {
            color: color('brown');
        }
    }
}
