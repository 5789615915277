.c-header {
    color: color('green', 'default');
    background-image: url('../../dist/images/bg-header-wood.jpg');
    background-repeat: repeat;
    background-position-x: -50;
    min-height: 144px;
    -webkit-box-shadow: 0px 36px 63px -38px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 36px 63px -38px rgba(0,0,0,0.75);
    box-shadow: 0px 36px 63px -38px rgba(0,0,0,0.75);

    @include  media(md) {
        border-top: solid 6px;
        min-height: 150px;
    }
    .c-header__top {
      padding-left: 0px;
      padding-right: 0px;
        @include media(md){
            min-height: rem(120px);
            padding-left: rem(14.6px);
            padding-right: rem(14.6px);
        }
        .logo {
            width: initial;
        }
    }
    .c-header__bottom{
        display: none;
        @include media(md){
            display: block;
        }
        .c-nav--desktop {
            max-width: rem(770px);
            margin-left: rem(378px);
            @include media(md){
                margin-left: rem(340px);
            }
        }
    }
}
