.c-content {
  //margin-top: rem(20px);
  //margin-bottom: rem(20px);
  @include media(md){
      margin-left: 0px;
      margin-right: 0px;
  }
    .o-col--inner {

        p {
            margin-bottom: 0px;
        }

    }
    .c-btn {
        padding: rem(16px) 0;
        max-width: rem(240px);
        font-family: $im-fell-dw-pica-sc;
        font-size: rem(14px);
        line-height: rem(14px);

    }
}
.c-content--quicklinks {
    min-height: 168px;
    background-color: color('green', 'light');
    display: none;
}
.c-content--intro {
    .c-btn--green {
        margin-top: rem(30px);
        padding: rem(16px) rem(20px);
        background-color: color('green', 'alt2');
    }
}
.c-content--interest{
    .c-btn--green {
        padding: rem(16px) rem(20px);
        background-color: color('green', 'dark');
        @include media(md){
            margin-top: rem(0px);
            margin-bottom: rem(50px);
        }
    }
}
.page {

    .c-content--main {
        min-height: 300px;
        padding-bottom: rem(45px);
        img {
            padding-bottom: rem(20px);
        }
        p {
            font-size: rem(20px);
            font-weight: 500;
            font-family: $montserrat-light;
            line-height: rem(35px);
            margin-top: rem(16px);

        }
        h4 {
            font-size: rem(26px);
            text-transform: none;
            font-family: $montserrat-regular;
            font-weight: 400;
            @include media(md){
                padding-top: rem(20px);
                padding-bottom: rem(25px);
            }
        }
        h3 {
            font-size: rem(30px);
            text-transform: none;
            font-family: $im-fell-dw-pica-sc;
            @include media(md){
                padding-top: rem(20px);
                padding-bottom: rem(10px);
            }
            a{ color: inherit; }
        }
        .c-content__header{
            @include media(md){
                padding-top: rem(30px);
                padding-bottom: rem(15px);
            }
            h1 {
                color: color('green', 'default');
                font-weight: 600;
            }
        }
        .o-col {
            h4 {
                font-size: rem(26px);
                text-transform: none;
                font-family: $montserrat-regular;
                font-weight: 400;
                @include media(md){
                    padding-top: rem(20px);
                    padding-bottom: rem(25px);
                }
            }
            h3 {
                font-size: rem(30px);
                text-transform: none;
                font-family: $im-fell-dw-pica-sc;
                @include media(md){
                    padding-top: rem(20px);
                    padding-bottom: rem(10px);
                }
            }
            .c-btn{
                max-width: initial;
                padding: rem(16px);
            }
            a{
                color: color('brown', 'default');
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            p {
                font-size: rem(20px);
                font-weight: 500;
                font-family: $montserrat-light;
                line-height: rem(35px);
                margin-top: rem(16px);

            }
            .c-btn {
                color: white;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }

            }
        }
    }

}
