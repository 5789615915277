#gform_wrapper_1 {
    color: color('brown', 'default');
    input,
    select,
    textarea {
        width: 100%;
        padding: rem(18px) rem(16px) !important;
        border: 1px solid color('brown', 'light');
        border-radius: 0;
        color: color('brown', 'dark');
    }

    input[type="submit"] {
        width: auto;
    }
    select#input_1_6,
    select#input_1_8,
    select#input_1_9,
    select#input_1_10,
    select#input_1_11,
    select#input_1_12,
    select#input_1_13,
    select#input_1_14,
    select#input_1_15,
    select#input_1_16,
    select#input_1_17,
    select#input_1_18,
    select#input_1_19 {
      height: rem(55px);
    }
}

.gform_button {
    padding: rem(8px) rem(15px) !important;
    font-size: em(18px);
    text-decoration: none;
    text-transform: uppercase;
    background-color: color('green', 'default');
    transition: background-color 0.35s;
    color: white !important;
    @include link-states() {
        background-color: color('brown', 'default');
        color: white;
    }
}

#gform_wrapper_2 {
    color: color('brown', 'default');
    input,
    select,
    textarea {
        width: 100%;
        padding: rem(18px) rem(16px) !important;
        border: 1px solid color('brown', 'light');
        border-radius: 0;
        color: color('brown', 'dark');
    }

    input[type="submit"] {
        width: auto;
    }
    select#input_2_4,
    select#input_2_8,
    select#input_2_9,
    select#input_2_10,
    select#input_2_11,
    select#input_2_12,
    select#input_2_13,
    select#input_2_14,
    select#input_2_15,
    select#input_2_16,
    select#input_2_17,
    select#input_2_18,
    select#input_2_19 {
        height: rem(55px);
    }
}


///
// jQuery UI
///
.ui-datepicker-header {
  background-color: color('brown', 'default');
  color: color('brown', 'default');
  a {
    color: white;

    @include link-states() {
      color: color('green', 'default');
    }
  }
}
