.c-logo {
    display: block;
    width: 100%;
    max-width: em(320px);
    margin: 0px auto;
    font-size: em(18px);
    text-align: center;
    @include media(md){
        display: inline-block;
        text-align: left;
    }
    img {
        position: relative;
        top: 0px;
        z-index: z('logo');
        -webkit-box-shadow: 10px 10px 41px -12px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 41px -12px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 41px -12px rgba(0,0,0,0.75);
        @include media(md){
            position: absolute;
        }
    }
}
