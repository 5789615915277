.c-btn {
    display: inline-block;
    padding: rem(5px) rem(10px);
    font-size: em(14px);
    min-height: rem(39px);
    color: color('white');
    font-family: $im-fell-dw-pica-sc;
    vertical-align: middle;
    min-width: 225px;
    line-height: em(36px);
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 0.35s;
}
///
// Green
///
.c-btn--green {
    background-color: color('green', 'light');
    color: color('white');
    @include link-states() {
        background-color: color('brown');
    }

}
///
// White
///
.c-btn--white {
    background-color: white;
    color: color('green', 'default');
    @include link-states() {
        background-color: color('brown');
    }

}


//
/// Header Button
///

.c-header{
    .c-btn--green{
        background-color: color('green', 'default');
        display: none;
        max-width: em(165px);
        @include media (md){
            display: block;
            float: right;
            font-size: em(18px);
        }
    }
}
