.c-content--attractions {
    min-height: 320px;
    background-size: cover;
    background-repeat: no-repeat;
    color: color('brown', 'default');
    font-family: $montserrat-light;
    padding-top: rem(45px);
    padding-bottom: rem(52px);
    padding-left: rem(30px);
    padding-right: rem(30px);
    @include media(md){
        padding-top: rem(50px);
        padding-bottom: rem(30px);
        padding-left: rem(0px);
        padding-right: rem(0px);
    }
    .c-content__inner {
        max-width: 70rem;
        margin: 0px auto;

        p{
            text-align: center;
            @include media(md){
                font-size: rem(20px);

            }

        }

        strong {
            font-size: rem(30px);
            color: color('green', 'default');
            font-family: $im-fell-dw-pica-sc;
            text-transform: uppercase;
            font-weight: 500;
            @include media (md) {
                font-size: rem(48px);
                line-height: rem(48px);
            }

        }
        .o-row{

            &:last-child{
                padding-top: rem(40px);
                margin: 0px auto;
            }

        }

    }

}
