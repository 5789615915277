.c-content--weather {
    background-image: url('../../dist/images/bg-weather.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: rem(210px);
    color: white;
    padding-top: rem(50px);
    .o-col--12\@xs {
        display: none;

        @include media(sm){
            &:nth-child(1),
            &:nth-child(2){
                display: block;
            }
        }
        @include media(md){
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3){
                display: block;
            }
        }
        @include media(lg){
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4){
                display: block;
            }
        }
        @include media(xl){
            display: block;

        }
        &:first-child{
            display: block;
        }

    }

    ///
    // Day
    ///
    .c-weather__day {
        color: color('grey', 'light');
        .wu-day-title {
          font-family: $im-fell-dw-pica-sc;
          font-size: rem(32px);
            text-transform: uppercase;
        }
    }
}
