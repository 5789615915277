///
// Breakpoints
///
$breakpoints: (
    xs: 480px,  // Extra Small devices (landscape phones, 480px and up)
    sm: 768px,  // Small devices (tablets, 768px and up)
    md: 1024px, // Medium devices (desktops, 1024px and up)
    lg: 1200px, // Large devices (desktops, 1200px and up)
    xl: 1500px  // Extra Lage devices (desktops, 1500px and up)
);

///
// Base global defaults
///
$base-columns:      12;
$base-gutter:       30px;
$base-font-size:    16px;
$base-font-family:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-line-height:  1.5;
$base-font-color:   #000000;

///
// Font sizes
///
$font-sizes: (
    'h1': (
        'initial':  46px,
        'sm':       36px
    ),
    'h2': (
        'initial':  40px,
        'sm':       30px
    ),
    'h3': (
        'initial':  36px,
        'sm':       24px
    ),
    'h4': (
        'initial':  32px,
        'sm':       20px
    ),
    'h5':           30px,
    'h6':           26px
);

///
// Column offsets
//
// Will enable the creation of column offset classes
///
$column-offsets: false;

///
// Debug mode
//
// In order to use the debug mode you need to set the below variable to true
// and you also need to set the THEME_DEBUG constant in the wp-config to true
///
$debug: false;

///
// Colors Map for @function color();
///
$colors: (
    'white': (
        'default':      #ffffff
    ),
    'beige': (
        'default':      #d7d1ca
    ),
    'grey': (
        'default':      #d9d3cc,
        'light':        #ebe9e6,
        'medium':       #e5e5e5,
        'dark':         #cccccc
    ),
    'bigrigmedia': (
        'orange':       #f6921e,
        'grey':         #4d4d4d
    ),
    'green': (
        'default':      #555f3f,
        'light':        #818d66,
        'medium':       #5b6546,
        'dark':         #495039,
        'alt':          #434c2e,
        'alt2':         #8b8d7a
    ),
    'brown': (
        'default':      #473421,
        'light':        #7f6245,
        'medium':       #543d26,
        'dark':         #402d1a
    )
);

///
// Alerts Map for @function alert();
///
$alerts: (
    'success': (
        'default':  #dff0d8,
        'message':  #3c763d,
        'border':   #d6e9c6
    ),
    'info': (
        'default':  #d9edf7,
        'message':  #31708f,
        'border':   #bce8f1
    ),
    'warning': (
        'default':  #fcf8e3,
        'message':  #8a6d3b,
        'border':   #faf2cc
    ),
    'error': (
        'default':  #f2dede,
        'message':  #a94442,
        'border':   #ebcccc
    )
);

///
// Social Networks Map for @function social();
///
$social-networks: (
    facebook:   #3b5998,
    twitter:    #00aced,
    googleplus: #dd4b39,
    linkedin:   #007bb6,
    youtube:    #bb0000,
    instagram:  #517fa4,
    pinterest:  #cb2027,
    flickr:     #ff0084,
    tumblr:     #32506d,
    foursquare: #0072b1,
    dribble:    #ea4c89,
    vine:       #00bf8f
);

///
// Z-index Map for @function z();
///
///
// Z-index Map for @function z();
///
$z-index: (
    debug:          1,
    logo:         100,
    nav-mobile:   200,
    nav-desktop:  400,
    dropdown:     300
);

///
// Fonts
///
$im-fell-dw-pica-sc:             'imfelldwpicasc';
$montserrat-regular:             'montserratregular';
$montserrat-light:               'montserratlight';
$montserrat-bold:                'montserratbold';
