.c-content--reviews {
    min-height: 300px;
    background-color: color('beige');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: rem(55px);
    padding-bottom: rem(40px);
    @include media(md) {
        padding-top: rem(70px);
        padding-bottom: rem(30px);
    }
    .c-content__inner {
        text-align: center;
        text-shadow: 2px 2px 4px black;
        h1,
        h4,
        p,
        span,
        a,
        cite {
            color: white;
        }

        h1 {
            font-size: rem(26px);
            font-weight: 600;
            @include media (md) {
                font-size: rem(48px);
            }

        }
        p {
            font-size: rem(17px);
            @include media(md){
                font-size: rem(26px);
            }

        }
        span {
            font-size: rem(46px);
            font-family: $im-fell-dw-pica-sc;
            @include media(md){
                font-size: rem(48px);
            }
        }
        .c-testimonials {
            padding-top: rem(10px);
            @include media(md){
                padding-top: rem(55px);
            }
        }

    }

}
