///
// Left
///
.u-text-left {
    text-align: left;
}


///
// Center
///
.u-text-center {
    text-align: center;
}


///
// Right
///
.u-text-right {
    text-align: right;
}
