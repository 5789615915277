.hero{
  .soliloquy-container {
    margin-bottom: 0px !important;
  }
}
.home {
  .hero {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      overflow: hidden;
      .soliloquy-outer-container {
          width: 100%;
      }
      .soliloquy-container {
        margin-bottom: 0px !important;
            .soliloquy-image {
                min-height: 380px!important;
                min-width: 1080px;
            }

      }

      ///
      // Hero Content
      ///
      .c-hero__content {
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          margin-left: auto;
          margin-right: auto;
          align-items: center;
          justify-content: center;

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
              text-shadow: 2px 3px 0 white;
          }

          h1 {
              color: color('white');

              // Medium Desktops
              @include media(md) {
                  font-size: rem(60px);
              }
          }
      }
  }
}
