h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: em(.5px);
}

h1 {
    @include font-size('h1');
    color: color('brown', 'default');
    a{ color:inherit; text-decoration: none;}
}

h2 {
    @include font-size('h2');
    color: color('green', 'default');
    a{ color:inherit; text-decoration: none;}
}

h3 {
    @include font-size('h3');
    color: color('green', 'default');
    a{ color:inherit; text-decoration: none;}
}

h4 {
    @include font-size('h4');
    color: color('brown', 'default');
    a{ color:inherit; text-decoration: none;}
}

h5 {
    @include font-size('h5');
    color: color('green', 'default');
    a{ color:inherit; text-decoration: none;}
}

h6 {
    @include font-size('h6');
    color: color('brown', 'default');
    a{ color:inherit; text-decoration: none;}
}
h1,
h2 {
    font-family: $im-fell-dw-pica-sc;
}
h3,
h4,
h5,
h6 {
    font-family: $montserrat-bold;
}
em {
    color: color('grey', 'dark');
}
p {
    font-size: $base-font-size;
    font-family: $montserrat-light;
    color: color('brown', 'default');
    margin: 0px;
    line-height: rem(34px);
    a{
        color: color('brown', 'default');
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
hr {
    color: color('grey', 'dark');
    border: solid rem(1px);
    margin-top: rem(30px);
    margin-bottom: rem(30px);
}
ul, li {
    color: color('brown', 'default');
    font-family: $montserrat-light;
    font-size: rem(16px);
    line-height: rem(34px);
}
em {
    font-weight: 400;
    color: color('brown', 'light');
}
