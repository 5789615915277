.c-content--interest {
    background-color: white;

    .o-row {
        margin-left: 0;
        margin-right: 0;

        @include media (md) {
            overflow: hidden;
            max-height: 400px;
        }

        .o-col {
            color: #fff;
            min-height: 320px;
            height: 100%;
            margin-top: 0px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            @include media (sm) {
                //padding-top: 1rem;
                min-height: 400px;
            }

            .o-col__inner {
                color: color('brown');
                @include media(md){
                    max-width: 440px;
                    margin-left: rem(36px);
                    margin-right: auto;
                }
                @include media(lg){
                    max-width: 540px;
                    margin-left: rem(36px);
                    margin-right: auto;
                }
                h1 {
                    font-size: rem(29px);
                    color: color('green', 'default');
                    text-align: left;
                    margin-top: rem(30px);
                    @include media(md){
                        margin-top: rem(40px);
                        font-size: rem(33px);
                        line-height: rem(38px);
                    }
                    @include media(lg){
                        margin-top: rem(40px);
                        font-size: rem(48px);
                        line-height: rem(58px);
                    }
                }
                p{
                  font-family: $montserrat-light;
                  font-size: rem(17px);
                  margin-bottom: rem(20px);
                    @include media(md){
                        font-size: rem(20px);
                        line-height: rem(36px);
                    }
                }
                strong{
                    font-weight: 600;
                    font-size: rem(17px);
                    font-family: $montserrat-bold;
                    @include media(md){
                        font-size: rem(20px);
                    }
                }
            }

        }
        .o-col--second{
            padding-left: rem(16px);
            padding-right: rem(16px);
            @include media(md){
                padding-left: rem(0px);
                padding-right: rem(0px);
            }
        }
    }

}
