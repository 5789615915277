.c-footer{
    min-height: 100px;
    background-color: color('green', 'default');
    font-size: em(11px);
    color: white;
    text-transform: uppercase;
    padding-top: rem(36px);
    .o-container {
        padding: 0px;
    }
}
.c-footer__top{
    text-transform: uppercase;
    color: white;
    padding-top: em(50px);
    font-family: $montserrat-regular;
    padding-bottom: rem(10px);
    display: none;
    @include media(md){
        display: block;
    }
    .o-row {
        margin: rem(0px);
    }
    .c-footer__block__title {
        font-size: em(24px);
        color: white;
    }
    .c-footer__block {
        width: 100%;
        display: none;

        @include media (sm) {
            display: block;
        }
        #menu-main-menu-2 {
            li {
                display: inline;
                float: left;
                margin-right: rem(15px);
            }
        }
        .menu {
            padding-left: 0px;
            li {
                list-style: none;
                text-decoration: none;
                margin-right: rem(15px);
            }
            .sub-menu {
                display: none;
            }
        }
        a {
            color: white;
            text-decoration: none;
        }
    }
}
.c-footer__bottom{
    color: white;
    text-transform: uppercase;
    font-family: $montserrat-light;
    padding-bottom: rem(28px);
    a{
        color: inherit;
        text-decoration: none;
        @include link-states(){
            color: white;
        }
    }
    p {
        color: inherit;
        font-size: rem(11px);
        @include media (md){
            margin-top: em(14px);
        }
    }
    .c-footer__copyright {
        margin: 0px auto;
        text-align: center;
        @include media(sm){

        }
        @include media(md){
            margin: 0px rem(90px);
            margin: auto;
            padding: initial;
            text-align: left;
        }
        span {
            display: block;
        }
    }
    .c-footer__logos {
        list-style: none;
        text-align: center;
        padding-left: 0px;
        margin: 0px auto;
        width: 270px;
        @include media(md){
            float: right !important;
        }
        img {
            width: initial;
            max-width: 70px;
            @inlcude media(xs){
                max-width: 80px;
            }
        }
        li {
            display: inline;
            float: left;
            margin-left: rem(7px);
        }
        li:first-child{
            padding-top: rem(4px);
            padding-right: rem(3px);
        }
    }
    .o-col:last-child{
        padding: 0px;
        margin: 0px auto;
        @include media(xs){
        }
    }
}
.c-footer__top,
.c-footer__bottom{
    .o-row{
        margin-left: rem(30px);
        margin-right: rem(30px);
    }
}

