.c-content--map {
    background-color: color('grey', 'default');
    .o-row {
      //negates negative margin
      margin: 0px;

        .o-col {
            @include media(md){
                padding: 0px;
            }

            .o-col__inner {
              margin: 0px auto;
                @include media(md){
                    padding-left: rem(45px);
                    margin-right: auto;
                }

                h2 {
                    font-size: rem(26px);
                    line-height: rem(28px);
                    @include media(sm){
                        font-size: rem(27px);
                    }
                }
                p {
                    margin-top: rem(16px);
                    a{
                      color: color('brown', 'default');
                      text-decoration: none;
                    }
                }
                .c-btn {
                  color: white;
                }

            }

        }
        .o-col--second {
            padding-top: rem(30px);
            padding-bottom: rem(30px);
            padding-left: rem(16px);
            padding-right: rem(16px);
            @include media(md) {
                padding-top: rem(55px);
                padding-bottom: rem(40px);
                padding-left: rem(0px);
                padding-right: rem(0px);
            }
        }

    }

}
