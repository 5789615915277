///
// Custom fonts
///

// Example
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }
@font-face {
    font-family: 'imfelldwpicasc';
    src: url('../../dist/fonts/IMFePIsc28P.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratregular';
    src: url('../../dist/fonts/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratlight';
    src: url('../../dist/fonts/Montserrat-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratbold';
    src: url('../../dist/fonts/Montserrat-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
